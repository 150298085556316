import React from "react";
import {
  SERVICE_RATE_TIMED_CODE_ID,
  TIMED_CODE_HOUR_ID,
  TIMED_CODE_MINUTES_ID,
  SERVICE_RATE_ITEM_ID,
  DATA_SAVED_STATE,
  GENERIC_DATA_SAVED_MESSAGE,
  INVOICE_CREATED_SUCCESSFULLY_MESSAGE,
  INVOICE_SAVED_STATE,
  PAYMENT_SUBMITTED_STATE,
  PAYMENT_SUBMITTED_SCUCCESSFULLY_MESSAGE,
  INVOICE_EDITED_STATE,
  INVOICE_EDITED_SUCCESSFULLY_MESSAGE,
  EXTERNAL_FUNDING_SOURCE_PAYMENT_RECEIVED_MESSAGE,
  EXTERNAL_FUNDING_SOURCE_PAYMENT_RECEIVED_STATE,
} from "./resources/referenceConstants";
import { BillableServiceLineItem } from "../apis/internalDb/billing/billing";
import { IPersonalInfoFormValues } from "../tabs/client/clientInterfaces";
import { ClientPersonalInfoDetails } from "../apis/internalDb/client/clientSummary/personalInfo/personalInfoRoutes";
import dayjs from "dayjs";
import { baseUrl } from "../apis/internalDb/internalConnection";
export const defaultNullUndefined = (value: any, defaultValue: any) => {
  return value == null ? defaultValue : value;
};

export const toStringSafe = (value: any) => {
  if (value == null) {
    return "";
  } else if (typeof value === "string") {
    return value;
  } else {
    return value.toString();
  }
};

export const parseIntSafe = (
  value: any,
  defaultValue: number | null | undefined
) => {
  if (value == null || isNaN(value)) {
    return defaultValue;
  } else if (typeof value === "string" && value === "") {
    return defaultValue;
  } else if (typeof value === "string") {
    return parseInt(value);
  } else {
    return value;
  }
};

export const parseFloatSafe = (
  value: any,
  defaultValue: number | null | undefined
) => {
  if (value == null || value === "") {
    return defaultValue;
  } else if (typeof value === "string") {
    return parseFloat(value);
  } else {
    return value;
  }
};

export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function displayServiceUnitLabel(
  rate_type_id: number | null,
  timed_code_type_id: number | null,
  isMins?: boolean
) {
  if (rate_type_id === SERVICE_RATE_TIMED_CODE_ID) {
    return timed_code_type_id === TIMED_CODE_HOUR_ID
      ? isMins
        ? "Min(s)"
        : "Hour(s)"
      : timed_code_type_id === TIMED_CODE_MINUTES_ID
      ? "Minute(s)"
      : "Unit(s)";
  } else if (rate_type_id === SERVICE_RATE_ITEM_ID) {
    return "Item(s)";
  } else {
    return "Unit(s)";
  }
}

export function timeToUnitsCalculation(time: number) {
  if (time < 8) return 0;
  const timeGap = 7;
  const timeBlock = 15;
  const unitCalc = (timeGap + time) / timeBlock;
  return Math.floor(unitCalc);
}

export function unitsToMinTimeCalculation(units: number) {
  if (units < 1) return 0;
  const firstUnit = 8;
  const timeBlock = 15;
  const timeCalc = firstUnit + (units - 1) * timeBlock;
  return timeCalc;
}

export function parDetailsToUsedMinutes(
  units: number,
  rate_type_id: number | null,
  timed_code_type_id: number | null
) {
  if (rate_type_id === SERVICE_RATE_TIMED_CODE_ID) {
    return timed_code_type_id === TIMED_CODE_HOUR_ID
      ? 60 * units
      : timed_code_type_id === TIMED_CODE_MINUTES_ID
      ? units
      : unitsToMinTimeCalculation(units);
  } else if (rate_type_id === SERVICE_RATE_ITEM_ID) {
    return 0;
  } else {
    return 0;
  }
}

export let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  currencySign: "accounting",
});

export function getPosition(options?: PositionOptions) {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  );
}

export function getDisplayId(number: number) {
  return number.toString().padStart(8, "0");
}

export function truncateText(text: string, length: number) {
  if (text.length <= length) {
    return text;
  }

  return text.substr(0, length) + "\u2026";
}

export function getSuccessMessage(successState: string | null) {
  switch (successState) {
    case INVOICE_SAVED_STATE:
      return INVOICE_CREATED_SUCCESSFULLY_MESSAGE;
    case DATA_SAVED_STATE:
      return GENERIC_DATA_SAVED_MESSAGE;
    case INVOICE_EDITED_STATE:
      return INVOICE_EDITED_SUCCESSFULLY_MESSAGE;
    case PAYMENT_SUBMITTED_STATE:
      return PAYMENT_SUBMITTED_SCUCCESSFULLY_MESSAGE;
    case EXTERNAL_FUNDING_SOURCE_PAYMENT_RECEIVED_STATE:
      return EXTERNAL_FUNDING_SOURCE_PAYMENT_RECEIVED_MESSAGE;
    default:
      return GENERIC_DATA_SAVED_MESSAGE;
  }
}

export function removeDuplicatesFromArray(arr: string[]) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export function validateServiceAuthAndClientMatch(
  values: BillableServiceLineItem[],
  setError: Function,
  setSelectedServices: Function
): boolean {
  if (values.length > 0) {
    let primaryAuthTypeToMatch = values[0].auth_party_type;
    let secondaryAuthTypeToMatch = values[0].secondary_auth_party_id;

    let client = values[0].map_org_to_client_id;

    let passesValidation = true;
    for (var value of values) {
      if (value.auth_party_type !== primaryAuthTypeToMatch) {
        passesValidation = false;
      }
      if (
        value.auth_party_type === primaryAuthTypeToMatch &&
        value.secondary_auth_party_id !== secondaryAuthTypeToMatch
      ) {
        passesValidation = false;
      }
      if (value.map_org_to_client_id !== client) {
        passesValidation = false;
      }
    }
    setError(!passesValidation);
    setSelectedServices(values);
    return !passesValidation;
  }
  setError(false);
  setSelectedServices(values);
  return false; // If values.length is 0, return false by default
}

export function manipulateServerClientDataForForm(
  clientData: ClientPersonalInfoDetails
) {
  let newPersonalInfo: IPersonalInfoFormValues = {
    mapClientId: clientData.map_org_to_client_id,
    displayClientId: defaultNullUndefined(clientData.display_client_id, ""),
    firstName: defaultNullUndefined(clientData.client.first_name, ""),
    lastName: defaultNullUndefined(clientData.client.last_name, ""),
    middleName: defaultNullUndefined(clientData.client.middle_name, ""),
    preferredName: defaultNullUndefined(clientData.client.preferred_name, ""),
    dob:
      clientData.client.dob === null || clientData.client.dob === undefined
        ? undefined
        : dayjs(clientData.client.dob?.substring(0, 10)),
    phoneNumber: defaultNullUndefined(clientData.client.phone_number, ""),
    email: defaultNullUndefined(clientData.client.email, ""),
    legalGenderId: toStringSafe(clientData.client.legal_gender_id),
    genderIdentityId: defaultNullUndefined(
      clientData.client.gender_identity_id,
      ""
    ),
    pronouns: defaultNullUndefined(clientData.client.pronouns, ""),
    householdIncome: defaultNullUndefined(
      clientData.client.household_income,
      0
    ),
    street1: defaultNullUndefined(clientData.client.street1, ""),
    street2: defaultNullUndefined(clientData.client.street2, ""),
    city: defaultNullUndefined(clientData.client.city, ""),
    stateId: toStringSafe(clientData.client.state_id),
    state: "",
    zipCode: defaultNullUndefined(clientData.client.zip_code, ""),
    raceId: toStringSafe(clientData.client.race_id),
    isEmployed:
      clientData.client.is_employed === null
        ? false
        : clientData.client.is_employed,
    employer: defaultNullUndefined(clientData.client.employer, ""),
    contactViaEmail: defaultNullUndefined(clientData.contact_via_email, false),
    contactViaPhone: defaultNullUndefined(clientData.contact_via_phone, false),
    contactViaText: defaultNullUndefined(clientData.contact_via_text, false),
  };
  return newPersonalInfo;
}

export async function computeSignedPassword(
  password: string,
  salt: string,
  signature: string
) {
  const textEncoder = new TextEncoder();

  const saltedPwData = textEncoder.encode(`${password}${salt}`);
  const saltedPwHash = await window.crypto.subtle.digest(
    "SHA-512",
    saltedPwData
  );
  const saltedPwHashHex = Array.from(new Uint8Array(saltedPwHash))
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  const signedPwData = textEncoder.encode(saltedPwHashHex);
  const signedPwHash = await window.crypto.subtle.sign(
    {
      name: "HMAC",
      hash: {
        name: "SHA-512",
      },
    },
    await window.crypto.subtle.importKey(
      "raw",
      textEncoder.encode(signature),
      {
        name: "HMAC",
        hash: "SHA-512",
      },
      true,
      ["sign", "verify"]
    ),
    signedPwData
  );

  const signedPwHashRaw = Array.from(new Uint8Array(signedPwHash))
    .map((byte) => String.fromCharCode(byte))
    .join("");
  const signedPwHashB64 = btoa(signedPwHashRaw);

  return signedPwHashB64;
}

export function generateClientDocumentUrl(
  customFormResponseId: number
): string {
  const rootURL = `${baseUrl}/customForm/generateDocument`;
  const params = new URLSearchParams();

  // Add other parameters
  params.append("customFormResponseId", customFormResponseId.toString());

  // Construct the final URL
  return `${rootURL}?${params.toString()}`;
}

export function displayFullName(
  firstName: string | null,
  lastName: string | null,
  preferredName?: string | null
): string {
  return `${defaultNullUndefined(firstName, "")} ${
    preferredName ? `(${preferredName})` : ""
  } ${defaultNullUndefined(lastName, "")}`;
}
