import internalConnection from "../internalConnection";
import { Dayjs } from "dayjs";
import { defaultNullUndefined } from "../../../shared/globalFunctions";

export const checkExistingEmail = async (email: string) => {
  const emailSearch: string = defaultNullUndefined(email, "");
  if (emailSearch === "") return false;
  const response = await internalConnection.get(
    "userDetails/checkExistingEmail",
    {
      params: {
        email: emailSearch,
      },
    }
  );
  return response.data.results as boolean;
};

export interface UserDetailsListType {
  user_details_id: number;
  user_id: number;
  fullname: string;
  position_label: string;
  calendar_hex_color: string;
  font_hex_color: string;
  is_default: boolean;
  is_therapist: boolean;
  is_supervisor: boolean;
  is_manager: boolean;
  is_biller: boolean;
  is_scheduler: boolean;
  is_admin: boolean;
  is_org_admin: boolean;
  can_access_all_clients: boolean;
  is_active: number;
}

export const getUserDetails = async (
  activeFilter: string,
  searchName: string | undefined,
  isAssignedCaseload?: boolean
) => {
  const searchNameValue: string = defaultNullUndefined(searchName, "");
  const response = await internalConnection.get("userDetails/search", {
    params: {
      activeFilter: activeFilter,
      searchName: searchNameValue,
      isAssignedCaseload: defaultNullUndefined(isAssignedCaseload, null),
    },
  });
  return response.data.results as UserDetailsListType[];
};

export const getUserSupervisees = async (
  orgProfileId: number,
  userId: number
) => {
  const response = await internalConnection.get("userDetails/getSupervisees", {
    params: {
      orgProfileId: orgProfileId,
      userId: userId,
    },
  });
  return response.data.results as UserDetailsListType[];
};

export interface UserPickerListType {
  user_id?: number;
  fullname: string;
}

export const getUserPicker = async (
  searchName: string,
  clientCaseId?: number,
  providerTypeId?: number,
  limitToMyOrgs?: boolean,
  excludeUserIds?: number[]
) => {
  const response = await internalConnection.get(
    "userDetails/searchUserPicker",
    {
      params: {
        searchName: searchName,
        clientCaseId: defaultNullUndefined(clientCaseId, null),
        providerTypeId: defaultNullUndefined(providerTypeId, null),
        limitToMyOrgs: limitToMyOrgs,
        excludeUserIds: excludeUserIds,
      },
    }
  );
  return response.data.results as UserPickerListType[];
};

export interface UserDetails {
  user_details_id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  mobile_number: string | null;
  work_number: string | null;
  other_number: string | null;
  hire_date: Dayjs | undefined | null;
  termination_date: Dayjs | undefined | null;
  start_working_hour: number | null;
  start_working_min: number | null;
  end_working_hour: number | null;
  end_working_min: number | null;
  position_id: number | null;
  is_part_time: boolean;
  max_hours: number | null;
  is_contractor: boolean;
  is_exempt: boolean;
  certs_and_titles: string | null;
  degrees: string | null;
  languages: string | null;
  calendar_hex_color: string;
  font_hex_color: string;
  default_service_location: number | null;
  can_access_ama_codes: boolean;
  npi: string | null;
  license_number: string | null;
  dea_number: string | null;
  pay_type_id: number | null;
  pay_rate: number | null;
  birth_date: Dayjs | undefined | null;
  drivers_license_number: string | null;
  gender_identity_id: number | null;
  pronouns: string | null;
  street1: string | null;
  street2: string | null;
  city: string | null;
  state_id: number | null;
  zip_code: string | null;
  provider_type_id: number | null;
  supervisor_user_id: number | null;
  supervisor_app_user: {
    user_details: {
      user_id: number;
      first_name: string;
      last_name: string;
    }[];
  } | null;
}

export interface UserDetailsDetailType {
  user_id: number;
  email: string;
  user_name: string;
  ssn: string | null;
  user_details: UserDetails[];
}

export const getUserDetailsById = async (id: number) => {
  const response = await internalConnection.get("userDetails/getById", {
    params: {
      id: id,
    },
  });
  return response.data.results as UserDetailsDetailType;
};

export interface SignatureDetails {
  signature_id: number | null;
  signature_text: string | null;
  user_id: number | null;
}
export const getSignatureById = async (id: number) => {
  const response = await internalConnection.get(
    "userDetails/getSignatureById",
    {
      params: {
        id: id,
      },
    }
  );
  return response.data.results as SignatureDetails;
};

export interface AddUserDetailsObject {
  password: string;
  passwordSalt: string;
  email: string;
  ssn: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  mobileNumber: string | null;
  workNumber: string | null;
  otherNumber: string | null;
  hireDate: Dayjs | undefined | null;
  terminationDate: Dayjs | undefined | null;
  startWorkingHour: number | null;
  startWorkingMin: number | null;
  endWorkingHour: number | null;
  endWorkingMin: number | null;
  positionId: number | null;
  isPartTime: boolean;
  maxHours: number | null;
  isContractor: boolean;
  isExempt: boolean;
  certsAndTitles: string | null;
  degrees: string | null;
  languages: string | null;
  calendarHexColor: string;
  fontHexColor: string;
  defaultServiceLocation: number | null;
  canAccessAmaCodes: boolean;
  npi: string | null;
  licenseNumber: string | null;
  deaNumber: string | null;
  payTypeId: number | null;
  payRate: number | null;
  birthDate: Dayjs | undefined | null;
  driversLicenseNumber: string | null;
  genderIdentityId: number | null;
  pronouns: string | null;
  street1: string | null;
  street2: string | null;
  city: string | null;
  stateId: number | null;
  zipCode: string | null;
  editingUserId: string;
  providerType: number | null;
  orgProfileId: number;
}

export const addUserDetails = async (userDetails: AddUserDetailsObject) => {
  return await internalConnection.post("userDetails/post", userDetails);
};

interface InviteUserByEmailPayload {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  editingUserId: string;
  orgId: number;
}

export const inviteUserByEmail = async (payload: InviteUserByEmailPayload) => {
  return await internalConnection.post("userDetails/userEmailInvite", payload);
};

export interface UpdateUserDetailsObject {
  userDetailsId: number;
  userId: number;
  email: string;
  ssn: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  mobileNumber: string | null;
  workNumber: string | null;
  otherNumber: string | null;
  hireDate: Dayjs | undefined | null;
  terminationDate: Dayjs | undefined | null;
  startWorkingHour: number | null;
  startWorkingMin: number | null;
  endWorkingHour: number | null;
  endWorkingMin: number | null;
  positionId: number | null;
  isPartTime: boolean;
  maxHours: number | null;
  isContractor: boolean;
  isExempt: boolean;
  certsAndTitles: string | null;
  degrees: string | null;
  languages: string | null;
  calendarHexColor: string;
  fontHexColor: string;
  defaultServiceLocation: number | null;
  canAccessAmaCodes: boolean;
  npi: string | null;
  licenseNumber: string | null;
  deaNumber: string | null;
  payTypeId: number | null;
  payRate: number | null;
  birthDate: Dayjs | undefined | null;
  driversLicenseNumber: string | null;
  genderIdentityId: number | null;
  pronouns: string | null;
  street1: string | null;
  street2: string | null;
  city: string | null;
  stateId: number | null;
  zipCode: string | null;
  editingUserId: string;
  providerType: number | null;
}

export const updateUserDetails = async (
  userDetails: UpdateUserDetailsObject
) => {
  return await internalConnection.put("userDetails/put", userDetails);
};

export interface signatureDetailsObject {
  signature: string;
  userDetailsId: number;
  userId: string;
}
export const addUserSignature = async (
  signatureDetails: signatureDetailsObject
) => {
  return await internalConnection.put(
    "userDetails/saveSignature",
    signatureDetails
  );
};

export interface ActivateUserDetailsObject {
  id: number;
  userId: string;
}

export const deactivateUserDetails = async (
  userDetails: ActivateUserDetailsObject
) => {
  return await internalConnection.put("userDetails/deactivate", userDetails);
};

export const reactivateUserDetails = async (
  userDetails: ActivateUserDetailsObject
) => {
  return await internalConnection.put("userDetails/reactivate", userDetails);
};

export interface UpdateUserProfileInfoObject {
  userDetailsId: number;
  userId: number;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string | null;
  mobileNumber: string | null;
  workNumber: string | null;
  otherNumber: string | null;
  street1: string | null;
  street2: string | null;
  city: string | null;
  stateId: number | null;
  zipCode: string | null;
  driversLicenseNumber: string | null;
  editUserId: string;
  genderIdentityId: number | null;
  pronouns: string | null;
}
export const updateUserProfile = async (
  userProfileDetails: UpdateUserProfileInfoObject
) => {
  return await internalConnection.put(
    "userDetails/updateUserProfile",
    userProfileDetails
  );
};

export const saveSignature = async (
  userProfileDetails: signatureDetailsObject
) => {
  return await internalConnection.post(
    "userDetails/saveSignature",
    userProfileDetails
  );
};

export const userDetailsKeys = {
  all: ["userDetails"] as const,
  lists: () => [...userDetailsKeys.all, "list"] as const,
  list: (activeFilter: string, searchName: string | undefined) =>
    [...userDetailsKeys.lists(), { activeFilter, searchName }] as const,
  details: () => [...userDetailsKeys.all, "detail"] as const,
  detail: (id: number) => [...userDetailsKeys.details(), id] as const,
  picker: (
    searchTerm: string,
    clientCaseId?: number,
    providerTypeId?: number,
    excludedUserIds?: number[]
  ) => [
    ...userDetailsKeys.all,
    "clientPicker",
    searchTerm,
    clientCaseId,
    providerTypeId,
    excludedUserIds,
  ],
  emailCheck: (email: string) => [...userDetailsKeys.all, email] as const,
  signatures: () => [...userDetailsKeys.all, "signature"] as const,
  signature: (id: number) => [...userDetailsKeys.signatures(), id] as const,
  superviseeList: (orgId: number) =>
    [...userDetailsKeys.all, "supervisees", orgId] as const,
};
